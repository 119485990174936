.GameHeader {
    display: flex;
    padding: 10px 15px;
    background-color: #e5e5e5;
    color: #363636;
}

.GameHeaderStatus {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
    gap: 20px;
}

.GameHeaderTailsContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.GameHeaderTailsCount {
    font-family: 'PressStart2P', monospace;
    font-size: 16px;
}

.GameHeaderTailsIcon {
    height: 20px;
    width: 20px;
}

@keyframes blink {
    0% { opacity: 1; }
    50% { opacity: .5; }
    100% { opacity: 1; }
}

.GameHeaderPause {
    display: flex;
    align-items: center;
    animation: 1s blink infinite;
    font-family: 'PressStart2P', monospace;
    font-size: 16px;
}
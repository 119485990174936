.Game {
    width: 100%;
    height: 100%;
}

.GameContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    background-color: #e5e5e5;
}
.GameControls {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    /*background-color: #ecececdd;*/
    background-color: #00000011;
    padding: 10px;
    border-radius: 10px;
    /*border-radius: 100%;*/
}

.GameControlsRow {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 50px;
}
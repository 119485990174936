.DirectionButton {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    width: 50px;
    border-radius: 20px;
    -webkit-tap-highlight-color: transparent;
    transition: 0.25s;
}

.DirectionButton:active {
    transform: scale(1.1);
    filter: brightness(75%);
}

.DirectionButtonClickableArea {
    position: absolute;
    height: 50px;
    width: 50px;
    opacity: 0;
    transform: rotate(45deg);
    z-index: 1;
}

.DirectionButtonDisabled {
    pointer-events: none;
    opacity: 0.5;
}

.Icon {
    height: 100%;
    width: 100%;
}
